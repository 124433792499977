<template>
  <UserLayout :layout="layouts.NO_SIDEBAR">
    <h4>Unauthorised access!</h4>
  </UserLayout>
</template>
<script>
import UserLayout, { LAYOUTS } from "@/components/Layout/UserLayout.vue";
export default {
  name: "Unauthorised",
  data() {
    return {
      layouts: LAYOUTS
    };
  },
  components: {
    UserLayout
  }
};
</script>
<style lang="scss" scoped>
h4 {
  text-align: center;
}
</style>
